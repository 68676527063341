/* General Container Styling */
.video-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.video-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
}

video {
  width: 100%;
  border-radius: 8px;
}

.video-label {
  margin-top: 10px;
  font-size: 14px;
  color: #ffffff;
}

/* Error Message Styling */
.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
}

/* Preloader Styling */
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust as per your design */
}

.preloader-logo {
  width: 100px;
  height: 100px;
}

@keyframes spin {
  100% {
      transform: rotate(360deg);
  }
}

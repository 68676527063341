/* Who Am I section styling */
.who-am-i-section {
    margin-top: 100px; /* Adjust margin as needed */
    text-align: center;
}

.who-am-i-section h2 {
    font-size: 36px;
    color: #EDF4F2; /* Match with your design theme */
    margin-bottom: 20px;
    margin-top: 20px;
}

.who-am-i-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Align items to the top for better layout */
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    max-width: 2150px;
}

.who-am-i-content div {
    max-width: 1000px; /* Limit width for readability */
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.about-me h2, .education h2, .work-experience h2 {
    font-size: 28px;
    color: #EDF4F2;
    margin-bottom: 10px;
    
}

.about-me p, .education p {
    font-size: 18px;
    line-height: 1.5;
    color: #EDF4F2;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: justify;
    word-spacing: 0.1em;

}

/* Media queries for responsive design */
@media (max-width: 1024px) {
    .who-am-i-content {
        flex-direction: column; /* Stack items vertically on medium screens */
        text-align: center; /* Center text on medium screens */
    }

    .who-am-i-content p {
        font-size: 16px; /* Adjust font size for medium screens */
    }
}

@media (max-width: 768px) {
    .who-am-i-content {
        gap: 15px; /* Adjust spacing for smaller screens */
    }

    .who-am-i-content p {
        font-size: 14px; /* Adjust font size for smaller screens */
        max-width: 100%; /* Allow text to take full width */
    }
}

@media (max-width: 480px) {
    .who-am-i-content {
        gap: 10px; /* Reduce spacing for small screens */
    }

    .who-am-i-content p {
        text-align: justify;
        word-spacing: 0.1em;
        font-size: 14px;
        padding: 0 10px; /* Add some padding for better fit */
    }

    .who-am-i-section h2 {
        font-size: 30px; /* Smaller heading size for mobile */
    }

    .about-me h2, .education h2 {
        font-size: 24px; /* Adjust subheading font size for mobile */
    }
}

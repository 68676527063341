/* General header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: #ffffff;
    position: relative;
}

.logo-link .logo {
    width: 90px;
    height: auto;
}
  

.nav {
    display: flex;
    align-items: center;
}

.nav-open {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 20px;
}

/* Navigation links styling */
.nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-top: auto;
    gap: 40px;
}

.nav-links li {
    margin: 10px 0;
}

.nav-links a {
    color: #ffffff; 
    text-decoration: none;
    font-size: 18px; 
}

.nav-links a:hover {
    color: #54FFB5; 
}

.nav-toggle-label {
    display: none;
}

.hamburger {
    width: 25px;
    height: 2px;
    background-color: #54FFB5; /* Adjust as needed */
    margin: 3px 0;
    transition: 0.4s;
}

.nav-open .hamburger {
    background-color: transparent;
  }

/* Button styling */
.btn-header {
    background-color: #54FFB5; 
    color: #133343; 
    border: none;
    padding: 20px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 30px 50px 0px ;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.btn-header:hover {
    background-color: #2bca85; 
    color: white;
    text-decoration: none;
}
.close-icon {
    font-size: 2rem;
    color: #54FFB5; /* Adjust color */
    cursor: pointer;
}

/* Mobile settings */
@media (max-width: 768px) {

    .logo-link .logo {
        width: 90px;
        margin-left: 20px;
    }
   
    .logo {
        width: 20px;
        height: auto;
    }

    .nav-toggle-label {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 30px;
        height: 20px;
        color: #ffffff;
        cursor: pointer;
    }

    .nav {
        display: none;
    }

    .nav-open {
        display: flex;
        background: #54FFB5;
        width: 75%;
        height: auto;
        margin-top: 25px;
        border: #133343 1px solid;
        box-shadow: 2px 2px 8px #95f1c9;
    }
    
    .nav-links {
        flex-direction: column;
        margin-left: 5px;
        list-style: none;
        padding: 0;
    }

    .nav-links li {
        margin: 10px 0;
    }

    .nav-links li a {
        color: #1B3F44;
    }

    .button-container {
        width: 70%;
        display: flex;
        justify-content: right;
        margin-top: 0;
    }

    .btn-header {
        width: 60%; /* Makes the button more responsive for mobile screens */
        text-align: center;
        border-radius: 30px 50px 0px ;
        padding: 15px; /* Adjust padding for better touch targets on mobile */
        font-size: 11px; /* Adjust font size for mobile */
        background-color: #54FFB5; 
        color: #133343; 
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-decoration: none;
    }
    
    .btn-header:hover {
        background-color: #2bca85; /* Hover effect */
        text-decoration: none;
    }
    
}

/* Work Experience Section Styling */
.work-experience-section {
    margin: 20px auto;
    padding: 20px;
    max-width: 2150px;
    color: #EDF4F2; /* Match the color theme */
}

.work-experience {
    margin-bottom: 40px;
    text-align: center;
}

.work-experience h2 {
    font-size: 28px;
    color: #EDF4F2;
    margin-bottom: 10px;
}

.work-experience p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: justify;
    word-spacing: 0.1em;
}

/* Skills Section Styling */
.skills {
    margin-bottom: 20px;
    text-align: center;
}

.skills h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #EDF4F2;
}

.skills ul {
    list-style-type: none;
    padding: 0;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap if necessary */
    gap: 15px; /* Space between items */
    justify-content: center; /* Center items horizontally */
}

.skills li {
    font-size: 16px;
    color: #EDF4F2;
    padding: 5px 10px; /* Add some padding around items */
    border: 1px solid #54ffb5; /* Optional: add border for better visual separation */
    border-radius: 5px; /* Optional: rounded corners */
}

/* Libraries Section Styling */
.libraries {
    margin-bottom: 20px;
    text-align: center;
}

.libraries h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #EDF4F2;
}

.libraries ul {
    list-style-type: none;
    padding: 0;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}

.libraries li {
    font-size: 16px;
    color: #EDF4F2;
    padding: 5px 10px;
    border: 1px solid #54ffb5;
    border-radius: 5px;
}

/* Frameworks Section Styling */
.frameworks {
    margin-bottom: 20px;
    text-align: center;
}

.frameworks h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #EDF4F2;
}

.frameworks ul {
    list-style-type: none;
    padding: 0;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}

.frameworks li {
    font-size: 16px;
    color: #EDF4F2;
    padding: 5px 10px;
    border: 1px solid #54ffb5;
    border-radius: 5px;
}

/* Animation Tools Section Styling */
.animation-tools {
    margin-bottom: 20px;
    text-align: center;
}

.animation-tools h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #EDF4F2;
}

.animation-tools ul {
    list-style-type: none;
    padding: 0;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}

.animation-tools li {
    font-size: 16px;
    color: #EDF4F2;
    padding: 5px 10px;
    border: 1px solid #54ffb5;
    border-radius: 5px;
}

/* Media queries for responsive design */
@media (max-width: 1024px) {
    .work-experience-section {
        padding: 20px; /* Add some padding for medium screens */
    }

    .work-experience {
        text-align: center; /* Center-align text for better readability */
    }

    .skills,
    .libraries,
    .frameworks,
    .animation-tools {
        margin-top: 20px; /* Add spacing between sections */
    }
}

@media (max-width: 768px) {
    .work-experience-section {
        flex-direction: column;
        padding: 15px; /* Adjust padding for smaller screens */
    }

    .work-experience p {
        font-size: 16px; /* Adjust font size for smaller screens */
    }

    .skills ul,
    .libraries ul,
    .frameworks ul,
    .animation-tools ul {
        padding-left: 20px; /* Ensure proper alignment */
    }

    .skills,
    .libraries,
    .frameworks,
    .animation-tools {
        margin-top: 15px; /* Reduce spacing between sections */
    }

    .skills h3,
    .libraries h3,
    .frameworks h3,
    .animation-tools h3 {
        font-size: 20px; /* Adjust headings for smaller screens */
    }
}

@media (max-width: 480px) {
    .work-experience-section {
        padding: 10px; /* Reduce padding for small screens */
    }

    .work-experience h2 {
        font-size: 24px; /* Reduce font size for headings */
    }

    .work-experience p {
        font-size: 14px; /* Reduce font size for paragraphs */
        line-height: 1.5; /* Improve readability */
    }

    .skills ul,
    .libraries ul,
    .frameworks ul,
    .animation-tools ul {
        font-size: 14px; /* Adjust font size for list items */
        padding-left: 10px; /* Ensure alignment */
    }

    .skills h3,
    .libraries h3,
    .frameworks h3,
    .animation-tools h3 {
        font-size: 18px; /* Further adjust heading size */
    }
}

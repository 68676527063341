.skills-section {
    margin-top: 80px;
    text-align: center;
}

.skills-section h2 {
    font-size: 46px;
    margin-bottom: 20px;
    color: #EDF4F2;
    margin-bottom: 100px;
}

.skills-grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skills-row {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Reduced gap for better balance */
}

.skill-column {
    flex: 1;
    background: url('../../assets/divBG.webp'),
     linear-gradient(to left, #edc9ce, #89e5fa, #fcffff, #ffffff, #ffffff); 
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 8px;
    color: #133343;
    font-size: 18px;
    text-align: center;
}

/* Skill content styling */
.skill-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 20px;
}

.skill-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}

.skill-image {
    max-width: 60px; /* Adjust the width as needed */
    height: auto;
}

.btn-contact {
    padding: 10px 20px;
    background-color: #1b3f44; /* Change to your preferred button color */
    color: #ffffff;
    text-decoration: none;
    border-radius: 50px;
    transition: background-color 0.3s;
}

.btn-contact:hover {
    background-color: #0b2d32; /* Hover color */
}

.skill-info {
    text-align: left;
}

.skill-info h3 {
    margin-bottom: 15px;
    text-align: justify;
}

.skill-info p {
    font-size: 14px;
    font-weight: 600;
    text-align: justify;
    word-spacing: 0.09em;
}

/* Responsive design for HomeServices */

/* Medium Screens: Tablets and small laptops (768px to 1024px) */
@media (max-width: 1024px) {
    .skills-grid {
        flex-direction: column; /* Stack rows vertically for medium screens */
        gap: 30px; /* Add spacing between rows */
    }

    .skills-row {
        flex-direction: column; /* Stack skill columns vertically */
        gap: 20px;
    }

    .skill-column {
        width: 100%; /* Ensure full width for columns */
        padding: 10px;
    }

    .skill-content {
        text-align: center; /* Center-align content */
    }

    .skill-image {
        width: 80%; /* Reduce image size for medium screens */
        height: auto;
    }

    .btn-contact {
        font-size: 16px; /* Adjust button font size */
        padding: 10px 15px; /* Adjust padding for medium screens */
    }

    .skill-info h3 {
        font-size: 20px; /* Reduce heading font size */
    }

    .skill-info p {
        font-size: 14px; /* Smaller font for descriptions */
    }
}

/* Small Screens: Mobile devices (up to 768px) */
@media (max-width: 768px) {
    .skills-section h2 {
        font-size: 28px; /* Reduce section heading font size */
        text-align: center;
    }

    .skills-row {
        gap: 15px; /* Adjust spacing between columns */
    }

    .skill-column {
        padding: 8px;
    }

    .skill-image {
        width: 70%; /* Further reduce image size for smaller screens */
    }

    .skill-info h3 {
        font-size: 18px; /* Smaller heading font */
    }

    .skill-info p {
        font-size: 13px; /* Compact font for descriptions */
        padding: 0 10px; /* Add padding for better readability */
    }

    .btn-contact {
        font-size: 14px; /* Smaller font size for buttons */
        padding: 8px 12px;
    }
}

/* Extra Small Screens: Very small mobile devices (up to 480px) */
@media (max-width: 480px) {
    .skills-section h2 {
        font-size: 24px; /* Further reduce heading font size */
    }

    .skill-content {
        gap: 10px; /* Reduce gap between image and text */
    }

    .skill-image {
        width: 60%; /* Shrink images for better fit */
    }

    .btn-contact {
        font-size: 12px; /* Compact button font size */
        padding: 6px 10px; /* Adjust padding for extra small screens */
    }

    .skill-info h3 {
        font-size: 16px; /* Smaller headings */
    }

    .skill-info p {
        font-size: 12px; /* Reduce description font size */
        line-height: 1.4; /* Adjust line height for readability */
    }
}

/* Main container for home component */
.home {
    padding: 40px;
    max-width: 2150px;
    margin: 0 auto; /* Center the content */
}

/* Upper section styling */
.upper-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}

.text-container {
    flex: 1;
    max-width: 50%;
    margin-right: 20px; /* Add space between text and image */
}

.text-container h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.text-container .heading-highlight {
    font-size: 50px;
    color: #54ffb5; /* Color for "Synergy Edge" */
}

.text-container p {
    font-size: 18px;
    line-height: 1.5;
    text-align: justify;
    word-spacing: 0.05em;
}

/* Social media icons styling */
.social-icons {
    display: flex;
    gap: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.icon {
    display: inline-block;
    width: 40px;
    height: 40px;
}

.icon-image {
    width: 100%;
    height: auto;
}

.image-container {
    position: relative;
    flex: 1;
    max-width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lottie-background {
    position: absolute;
    width: 120%; /* Adjust as needed */
    height: 120%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.profile-image {
    position: relative;
    width: 90%;
    height: auto;
    border-radius: 10px;
    z-index: 2;
}


.image-container img {
    margin-top: 30px;
    width: 90%;
    height: auto;
    border-radius: 10px; /* Optional: rounded corners */
}

/* Lower section styling */
.lower-section {
    display: flex;
    justify-content: space-between; /* Ensures even spacing between items */
    align-items: center;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    color: #EDF4F2;
    margin-bottom: 40px;
    margin-top: 95px;
    padding: 0 40px; /* Optional: Adds padding to the left and right */
}

/* Icon section for individual items */
.icon-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    font-size: 18px;
    min-width: 150px;
    margin: 0 auto; /* Ensures it centers on smaller screens */
}

.icon-image {
    width: 60px;
    height: auto;
    margin-bottom: 10px;
}

.icon-section p {
    font-size: 20px;
    color: #54ffb5;
}

/* Media queries for responsive design */
@media (max-width: 1024px) {
    .upper-section {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .text-container {
        max-width: 100%;
        margin-right: 0;
    }

    .image-container {
        max-width: 100%;
        margin-top: 20px;
    }

    .social-icons {
        justify-content: center;
        margin-right: 10px;
    }
}

@media (max-width: 768px) {
    .lower-section {
        flex-direction: column;
        justify-content: center;
        gap: 20px; /* Add space between rows for small screens */
        padding: 20px; /* Optional: Adjust padding for smaller screens */
    }

    .icon-section {
        margin: 10px 0;
    }

   
}

@media (max-width: 480px) {
    .social-icons {
        gap: 30px; /* Adjusted for small screens */
    }

    .icon {
        width: 30px; /* Reduce icon size */
        height: 30px;
    }

    .icon-image {
        width: 50px; /* Further reduce icon size */
    }

    .text-container h1 {
        font-size: 28px; /* Smaller text for better mobile fit */
    }

    .text-container p {
        font-size: 16px;
    }


  

}

